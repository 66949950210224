@import './variables.css';
/* ++++++++++++++++++++++++++++++ */
/* HEADER SECTION */
/* ++++++++++++++++++++++++++++++ */

.header {
  position: relative;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-primary);
  padding: 0px 4.8rem;
}

.main-nav {
  display: flex;
  gap: 3.6rem;
}

.main-nav-list {
  display: flex;
  align-items: center;
  gap: 3.6rem;
  list-style: none;
  border-bottom: 0.4rem solid var(--color--logo-icon-primary);
}

.main-nav-link:link,
.main-nav-link:visited {
  font-size: 1.8rem;
  font-weight: 500;
  text-decoration: none;
  color: var(--color-tertiary);
  transition: all 0.3s;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: var(--color-font-primary);
}

.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  background-color: var(--color-tertiary);
  color: var(--color-font-primary);
  padding: 1.2rem 3rem;
  border-radius: 12px;
  transition: all 0.3s;
}

.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
  background-color: var(--color-hover-primary);
  color: var(--color-font-tertiary);
}

/* MOBILE */
.btn-mobile-nav {
  border: transparent;
  background: none;
  cursor: pointer;
  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: var(--color-font-secondary);
}

.icon-close {
  display: none;
}

/* ++++++++++++++++++++++++++++++ */
/* FOOTER SECTION */
/* ++++++++++++++++++++++++++++++ */

.footer {
  padding: 9.6em 0;
  background-color: var(--color-primary);
}

.grid--footer {
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
}

.disclaimer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.4rem;
  color: var(--color-secondary-tint);
  margin-top: 1.2rem;
}

.sub-nav-title {
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--color-font-tertiary);
  margin-bottom: 4rem;
}
.sub-nav-list {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  list-style: none;
  color: var(--color-font-tertiary);
}
.sub-nav-link:link,
.sub-nav-link:visited {
  font-size: 1.6rem;
  color: var(--color-font-tertiary);
  text-decoration: none;
}

.sub-nav-link:hover,
.sub-nav-link:active {
  color: var(--color-font-primary);
}

.sub-nav-icon {
  width: 2.4rem;
  height: 2.4rem;
}

/* ++++++++++++++++++++++++++++++ */
/* INTRO COMPONENT */
/* ++++++++++++++++++++++++++++++ */

.section-intro {
  padding: 4.8rem 0 9.6rem 0;
  background-color: var(--color-secondary);
  background: var(--linear-gradient-primary--top);
  color: var(--color-font-primary);
}

.intro {
  max-width: 130rem;
  /* center hero section*/
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9.6rem;
  align-items: center;
}

.intro-imgs {
  border-radius: 24px;
  box-shadow: var(--shadow--primary);
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-imgs--wrap {
  flex-wrap: wrap;
}

.intro-imgs-flex {
  display: flex;
  flex-wrap: wrap;
  border-radius: 24px;
  box-shadow: var(--shadow--primary);
  overflow: hidden;
}

.intro-img--lg {
  width: 100% !important;
}

.intro-img {
  width: 50%;
  /* border-radius: 24px;
  box-shadow: var(--shadow--primary); */
}

.intro-boost {
  font-size: 2.4rem;
  margin-bottom: 3.2rem;
  color: var(--color-font--heading-primary);
}

.intro-description {
  font-size: 2rem;
  margin-bottom: 4.8rem;
  line-height: 1.35;
}

/* ++++++++++++++++++++++++++++++ */
/* GRID OF BOXES COMPONENT */
/* ++++++++++++++++++++++++++++++ */

.boxes-grid {
  display: grid;
  /* gap: 2.4rem; */
  column-gap: 3rem;
  row-gap: 6.4rem;
}

.boxes-box {
  border-radius: 24px;
  transition: box-shadow 0.6s;
}

.boxes-box .boxes-img-box {
  transition: box-shadow 0.6s;
}

.boxes-box:hover {
  box-shadow: var(--shadow--secondary);
}

.boxes-box:hover .boxes-img-box {
  box-shadow: none;
}

.boxes-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow--primary);
  padding: 1.8rem;
  border-radius: 24px;
}

.boxes-img-box img {
  width: 100%;
}

.boxes-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  padding: 2.4rem;
}

.boxes-name {
  text-align: center;
  font-size: 2.4rem;
  font-weight: 600;
}

.boxes-info {
  font-size: 1.8rem;
  text-align: center;
  line-height: 1.35;
}
